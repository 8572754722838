import { Component, computed, inject, input, Input, Signal } from '@angular/core';
import { Article } from '@lobos/common-v3';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgClass, NgIf } from '@angular/common';
import { LbsFavoriteItem } from '../../store/model/lbs-favorite-item.interface';
import { GlobalStore } from '../../store/global.store';
import { TranslocoService } from '@jsverse/transloco';
import { first, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { NotLoggedInModalComponent } from '../not-logged-in-modal/not-logged-in-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-article-favorite',
  templateUrl: './article-favorite.component.html',
  styleUrls: ['./article-favorite.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass],
})
export class ArticleFavoriteComponent {
  article = input.required<Article>()
  @Input() showAsButton: boolean = false;
  @Input() onArticlePage = false;
  quantity = input<number>(1);

  transloco = inject(TranslocoService);
  dialog = inject(MatDialog);
  store = inject(GlobalStore);

  favoriteItem: Signal<LbsFavoriteItem | undefined> = computed(() =>
    this.store.activeFavoriteList()?.listItems.find((item) => item.oArticle?.sArticleID == this.article().sArticleID),
  );

  addOrRemoveFavoriteItem() {
    if (this.store.isLoggedIn()) {
      if (this.favoriteItem()) {
        this.store.deleteFavoriteItem(this.store.activeFavoriteListId()!, this.favoriteItem()!.shtItemID.toString());
      } else {
        this.store.createFavoriteItem(
          {
            decQuantity: this.quantity(),
            oArticle: this.article(),
            gListID: this.store.activeFavoriteListId()!,
          },
          this.store.activeFavoriteListId(),
        );
      }
    } else {
      this.transloco
        .selectTranslate('shared.dialog.not-logged-in.text')
        .pipe(
          first(),
          tap((text) => this.dialog.open(NotLoggedInModalComponent, { data: { text: text } })),
        )
        .subscribe();
    }
  }
}
