<div class="w-full p-6" *transloco="let t; scope: 'shared'">
  <h2 class="text-3xl font-bold mb-2">{{ t("shared.dialog.not-logged-in.title") }}</h2>
  <p class="mt-3">{{ data.text || t("shared.dialog.not-logged-in.text") }}</p>

  <div class="flex justify-between mt-3">
    <app-text-button [text]="t('shared.dialog.not-logged-in.okay')" (click)="onCancel($event)"></app-text-button>
    <app-text-button [text]="t('shared.dialog.not-logged-in.login')" (click)="goToLogin()" [primary]="true"></app-text-button>
  </div>

</div>
