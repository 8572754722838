import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { Location } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoDirective } from '@jsverse/transloco';
import { Router } from '@angular/router';
import { UrlHelperService } from '../../services/url/url-helper.service';
import { TextButtonComponent } from '../button/text-button/text-button.component';

@Component({
  selector: 'app-not-logged-in-modal',
  standalone: true,
  templateUrl: './not-logged-in-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoDirective, TextButtonComponent],
})
export class NotLoggedInModalComponent {
  dialogRef = inject(MatDialogRef<NotLoggedInModalComponent>);
  router = inject(Router);
  urlHelperService = inject(UrlHelperService);
  location = inject(Location)

  constructor(@Inject(MAT_DIALOG_DATA) public data: { text: string }) {}
  onCancel(e: Event) {
    e.stopPropagation();
    this.dialogRef.close();
  }

  goToLogin() {
    this.router.navigate([this.urlHelperService.getUrlPath('/')], { queryParams: { redirect: this.location.path() } });
    this.dialogRef.close();
  }
}
